import * as yup from 'yup'

import { isBefore } from 'date-fns'
import { Message } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OTHER_BREED } from '../../data/breeds'
import { useDate } from '../../hooks/useDate'
import { useRegistration } from '../../hooks/useRegistration'
import { arrivalDateSchema } from './InputArrivalDate'

export const MAX_NAME_LENGTH = 80
export const MAX_ADDRESS_LENGTH = 80
export const MAX_BREED_DESCRIPTION_LENGTH = 255
export const MAX_MICROCHIP_NUMBER_LENGTH = 15
// Allowed text characters
export const textRegex = /^[\u0020-\u007e|\u00c0-\u017F]+$/
const descriptionRegex = /^[\u0020-\u007e|\u00c0-\u017F|\u000A]+$/

export const getRegistrationFormSchema = () => {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()

  const { getRegistrationLegalDate, getRegistrationMinDate, getRegistrationMaxDate } =
    useRegistration()

  const LEGAL_DATE = getRegistrationLegalDate()
  const MIN_DATE = getRegistrationMinDate()
  const MAX_DATE = getRegistrationMaxDate()

  // Allowed characters for microchipNumber
  const microchipNumberRegex = /^[0-9]+$/

  const errorMessages = {
    generic: t('registrationForm.error.ifEmpty.generic') as Message,
    chooseOne: t('registrationForm.error.ifEmpty.chooseAtLeastOne') as Message,
    date: {
      wrongFormat: t('registrationForm.error.date.wrongFormat') as Message,
      max: t('registrationForm.error.date.max') as Message,
      misMatch: t('registrationForm.error.date.microchipMismatch') as Message,
    },
    description: {
      wrongFormat: t('registrationForm.error.description.wrongFormat') as Message,
    },
    microchip: {
      notEnough: t('registrationForm.error.microchipNumber.notEnough') as Message,
      wrongFormat: t('registrationForm.error.microchipNumber.wrongFormat') as Message,
    },
    name: {
      wrongFormat: t('registrationForm.error.names.wrongFormat') as Message,
    },
    microchipSetterName: {
      max: t('registrationForm.error.microchipSetterName.max', {
        max: MAX_NAME_LENGTH,
      }) as Message,
    },
    microchipSetterAddress: {
      max: t('registrationForm.error.microchipSetterAddress.max', {
        max: MAX_ADDRESS_LENGTH,
      }) as Message,
    },
  }

  return yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    microchipNumber: yup
      .string()
      .min(MAX_MICROCHIP_NUMBER_LENGTH, errorMessages.microchip.notEnough)
      .max(MAX_MICROCHIP_NUMBER_LENGTH)
      .matches(microchipNumberRegex, errorMessages.microchip.wrongFormat)
      .required(errorMessages.generic),

    name: yup
      .string()
      .min(1, errorMessages.generic)
      .max(MAX_NAME_LENGTH)
      .matches(textRegex, errorMessages.name.wrongFormat)
      .required(errorMessages.generic),

    kennelName: yup
      .string()
      .max(MAX_NAME_LENGTH)
      .matches(textRegex, { message: errorMessages.name.wrongFormat, excludeEmptyString: true })
      .nullable()
      .notRequired(),

    birthDate: yup
      .date()
      .nullable()
      .transform((curr: any, orig: any) => (orig === '' || isNaN(orig?.valueOf()) ? null : curr))
      .typeError(errorMessages.date.wrongFormat)
      .required(errorMessages.generic)
      .transform((value) => !!value && toUTCDate(value))
      .min(MIN_DATE, errorMessages.date.wrongFormat)
      .max(MAX_DATE, errorMessages.date.max),

    birthCountryCode: yup.string().required(errorMessages.generic),

    // Breedcode is nullable in the backend, as NULL maps to "other breed", but
    // within the client side form validation null shouldn't be allowed as the
    // field is a required value.
    breedCode: yup.string().required(errorMessages.generic),

    sexCode: yup.string().required(errorMessages.chooseOne),

    breedSize: yup.string().when('breedCode', {
      is: (value: string) => value === OTHER_BREED,
      then: (schema: yup.StringSchema) => schema.required(errorMessages.chooseOne),
    }),

    breedDescription: yup
      .string()
      .max(MAX_BREED_DESCRIPTION_LENGTH)
      .matches(descriptionRegex, {
        message: errorMessages.description.wrongFormat,
        excludeEmptyString: true,
      })
      .when('breedCode', {
        is: (value: string) => value === OTHER_BREED,
        then: (schema: yup.StringSchema) => schema.trim().required(errorMessages.generic),
        otherwise: (schema: yup.StringSchema) => schema.notRequired(),
      }),

    colours: yup
      .array()
      .of(yup.string())
      .min(1, errorMessages.chooseOne)
      .required(errorMessages.chooseOne),

    microchipDate: yup
      .date()
      .typeError(errorMessages.date.wrongFormat)
      .transform((value) => (!!value ? toUTCDate(value) : null))
      .when('birthDate', {
        is: (value: Date) => (!!value ? !isBefore(toUTCDate(value), LEGAL_DATE) : false),
        then: (schema: yup.DateSchema) =>
          schema
            .required(errorMessages.generic)
            .min(MIN_DATE, errorMessages.date.wrongFormat)
            .max(MAX_DATE, errorMessages.date.max),
        otherwise: (schema: yup.DateSchema) =>
          schema
            .notRequired()
            .min(MIN_DATE, errorMessages.date.wrongFormat)
            .max(MAX_DATE, errorMessages.date.max),
      })
      .when('birthDate', ([birthDate]: Date[], schema: yup.DateSchema) => {
        return birthDate && !isNaN(birthDate.valueOf())
          ? schema.min(toUTCDate(birthDate), errorMessages.date.misMatch)
          : schema.notRequired()
      }),

    microchipSetterName: yup.string().when('birthDate', {
      is: (value: Date) => (!!value ? !isBefore(toUTCDate(value), LEGAL_DATE) : false),
      then: (schema: yup.StringSchema) =>
        schema
          .required(errorMessages.generic)
          .max(MAX_NAME_LENGTH, errorMessages.microchipSetterName.max)
          .matches(textRegex, {
            message: errorMessages.name.wrongFormat,
            excludeEmptyString: true,
          }),
      otherwise: (schema: yup.StringSchema) =>
        schema
          .optional()
          .nullable()
          .max(MAX_NAME_LENGTH, errorMessages.microchipSetterName.max)
          .matches(textRegex, {
            message: errorMessages.name.wrongFormat,
            excludeEmptyString: true,
          }),
    }),

    microchipSetterAddress: yup.string().when('birthDate', {
      is: (value: Date) => (!!value ? !isBefore(toUTCDate(value), LEGAL_DATE) : false),
      then: (schema: yup.StringSchema) =>
        schema
          .required(errorMessages.generic)
          .max(MAX_ADDRESS_LENGTH, errorMessages.microchipSetterAddress.max)
          .matches(textRegex, {
            message: errorMessages.name.wrongFormat,
            excludeEmptyString: true,
          }),
      otherwise: (schema: yup.StringSchema) =>
        schema
          .optional()
          .nullable()
          .max(MAX_ADDRESS_LENGTH, errorMessages.microchipSetterAddress.max)
          .matches(textRegex, {
            message: errorMessages.name.wrongFormat,
            excludeEmptyString: true,
          }),
    }),
  })
}
