import { useEffect } from 'react'
import useUserApi from '../../hooks/useUserApi'

function TokenUpdateInterval() {
  const UPDATE_TIME_MS: number = 1000 * 60 * 15 // 15 minute

  const { fetchCurrentUser } = useUserApi()

  useEffect(() => {
    const updateInterval = setInterval(() => {
      fetchCurrentUser()
    }, UPDATE_TIME_MS)

    // Clean up the interval on component unmount
    return () => clearInterval(updateInterval)
  }, [])

  return <></>
}

export default TokenUpdateInterval
