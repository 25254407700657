import { AppBar, Box, Button, Divider, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAuthContext } from '../../context/AuthProvider'
import { AppRoutes } from '../../routes'
import LangMenu from './LangMenu'
import LogoutTimer from './LogoutTimer'
import ProfileMenu from './ProfileMenu'
import TokenUpdateInterval from './TokenUpdateInterval'
import logo from '/ruokavirasto-logo-noText-white.svg'

function Header() {
  const { t } = useTranslation('common')
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  const navigateToHome = () => {
    !!currentUser ? navigate(AppRoutes.Dashboard) : navigate(AppRoutes.Root)
  }

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column' },
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'inherit', alignItems: 'center' }}>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              img: { maxHeight: '37px', maxWidth: '51px' },
            }}
          >
            <Button
              onClick={navigateToHome}
              variant="text"
              aria-label={t('header.homeButtonAria') as string}
            >
              <img src={logo} aria-hidden="true" />
            </Button>
          </Box>
          <Divider
            orientation="vertical"
            aria-hidden="true"
            flexItem
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: 'none', sm: 'initial' },
              borderColor: 'decor.light',
              transform: 'scaleY(60%)',
            }}
          />
          <Typography
            variant="body1"
            sx={{
              textTransform: 'uppercase',
              letterSpacing: '0.1rem',
              fontWeight: 700,
              color: 'text.secondary',
            }}
          >
            {t('header.title')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LangMenu />
          <Divider
            orientation="vertical"
            aria-hidden="true"
            flexItem
            sx={{ mr: 1, borderColor: 'decor.light' }}
          />
          <ProfileMenu />
          {!!currentUser && <LogoutTimer />}
          {!!currentUser && <TokenUpdateInterval />}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
