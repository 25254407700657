import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../routes'

// Debounce utility function
function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null

  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout)

    timeout = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

function LogoutTimer() {
  const USAGE_TIME_MS: number = 1000 * 60 * 360 // 360 minutes
  const IDLE_TIME_MS: number = 1000 * 60 * 60 // 60 minutes

  const navigate = useNavigate()

  const usageTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const idleTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const resetIdleTimer = () => {
    // Clear the existing timeout if it's running
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current)
    }

    idleTimeoutRef.current = setTimeout(() => {
      navigate(AppRoutes.Logout) // Trigger logout after idle time limit
    }, IDLE_TIME_MS)
  }

  const startUsageTimer = () => {
    if (usageTimeoutRef.current !== null) {
      clearTimeout(usageTimeoutRef.current) // Just in case it was already running
    }

    usageTimeoutRef.current = setTimeout(() => {
      navigate(AppRoutes.Logout) // Trigger logout after usage time limit
    }, USAGE_TIME_MS)
  }

  // Function to detect user activity (use debounce so that resetTimer isn't called excessively e.g. mousemove)
  const handleActivity = debounce((): void => {
    resetIdleTimer() // Reset the timer on any user activity
  }, 300)

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)
    window.addEventListener('scroll', handleActivity)
    window.addEventListener('click', handleActivity)

    // Start the idletimer when the component mounts
    resetIdleTimer()
    // Start the continuous usage timer when the component mounts
    startUsageTimer()

    // Clean up the event listeners and timer when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      window.removeEventListener('scroll', handleActivity)
      window.removeEventListener('click', handleActivity)

      if (idleTimeoutRef.current !== null) {
        clearTimeout(idleTimeoutRef.current)
      }

      if (usageTimeoutRef.current !== null) {
        clearTimeout(usageTimeoutRef.current)
      }
    }
  }, [])

  return <></>
}

export default LogoutTimer
